.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.filter-blur {
  opacity: 0.8;
}

/* Spin loader css */
.ant-spin-dot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.ant-spin-dot-spin {
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-dot i {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background-color: #1890ff;
  transform: scale(0.75);
  display: block;
  position: absolute;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
  transform-origin: 50% 50%;
}
.ant-spin-dot i:nth-child(1) {
  left: 0;
  top: 0;
}
.ant-spin-dot i:nth-child(2) {
  right: 0;
  top: 0;
  animation-delay: 0.4s;
}
.ant-spin-dot i:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin-dot i:nth-child(4) {
  left: 0;
  bottom: 0;
  animation-delay: 1.2s;
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}

.ajax-global-spin {
  height: 100vh !important;
  max-height: unset !important;
}

.ajax-global-spin {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
}

@keyframes fade {
  from {
    box-shadow: 0 2px 10px #4e4e4e29;
  }
  50% {
    box-shadow: 0 1px 10px #dd4b39;
  }
  to {
    box-shadow: 0 2px 10px #4e4e4e29;
  }
}

@-webkit-keyframes fade {
  from {
    box-shadow: 0 2px 10px #4e4e4e29;
  }
  50% {
    box-shadow: 0 1px 10px #dd4b39;
  }
  to {
    box-shadow: 0 2px 10px #4e4e4e29;
  }
}

.blink {
  animation: fade 1000ms infinite;
  -webkit-animation: fade 1000ms infinite;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* #canvas {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100% !important;
  height: auto !important;
  bottom: 0;
}
.canvas-container {
  position: relative;
  width: 100% !important;
  height: 100% !important;
}
.upper-canvas {
  width: 100% !important;
  height: 100% !important;
} */

/* chips style */
ul.chips-main-content {
  list-style: none;
  padding: 0;
  text-align: left;
  margin: 0px 13px 13px 13px;
}
ul.chips-main-content li {
  display: inline;
}
ul.chips-main-content li label {
  display: inline-block;
  background-color: #f9f9f9;
  border: 2px solid #e8e8e8;
  color: #909698;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 3px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.chips-main-content li label {
  padding: 8px 16px;
  cursor: pointer;
}

ul.chips-main-content li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  transition: transform 0.3s ease-in-out;
}

ul.chips-main-content li input[type="checkbox"]:checked + label::before {
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

ul.chips-main-content li input[type="checkbox"]:checked + label {
  border: 2px solid #3c9ffa;
  background-color: #3c9ffa;
  color: #fff;
  transition: all 0.2s;
}

ul.chips-main-content li input[type="checkbox"] {
  display: absolute;
}
ul.chips-main-content li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

/* floor style */
.actions {
  position: absolute;
  width: 22px;
  height: auto;
  border-radius: 6px;
  background: #e7ecf2 0% 0% no-repeat padding-box;
  border: 1px solid #b8c1cc;
  padding: 7px 4px;
  display: none;
}

.actions-decorate {
  position: absolute;
  width: 22px;
  height: auto;
  border-radius: 6px;
  background: #e7ecf2 0% 0% no-repeat padding-box;
  border: 1px solid #b8c1cc;
  padding: 7px 4px;
  display: none;
}

.border-x {
  height: 40px;
  width: 5px;
  background-color: #b8c1cc;
}

.border-y {
  margin-top: 18px;
  height: 5px;
  width: 40px;
  background-color: #b8c1cc;
}

/* menu image */

.image-area {
  position: relative;
}
.image-area img {
  max-width: 100%;
  height: 40px;
}
.remove-image {
  display: none;
  position: absolute;
  top: -8px;
  height: 17px;
  right: -8px;
  text-decoration: none;
  background: #fff;
  border-radius: 17px;
  font: 700 21px/20px sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}

/* emptyView */
.emptyView {
  width: 100%;
  align-items: center;
  height: calc(100vh - 250px);
  display: flex;
}

/* notify */
.push-notification {
  position: fixed;
  top: 70px;
  width: 300px;
  right: 0;
  padding: 12px 24px 17px 24px;
  vertical-align: middle;
  border-radius: 1px;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  z-index: 9999;
}
.notification-card {
  background: white;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* order list menu */
.action-menu {
  content: " ";
  background: linear-gradient(to bottom, #57c1eb 0%, #246fa8 100%);
  box-shadow: 4px 8px 24px rgba(45, 53, 66, 0.2);
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  width: 70px;
  right: 23px;
}

.action-menu ul::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 70%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #57c1eb transparent;
}
.action-menu ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.action-menu ul li {
  display: block;
  padding: 3px 10px 2px 6px;
  color: #fff;
  font-weight: 700;
  border-bottom: 1px solid;
}
.toast-notification {
  top: unset !important;
  bottom: 0;
  transform: none !important;
}
.toast-notification > span {
  padding: 0 !important;
  border-radius: 4px 4px 0px 0px !important;
}

.toast-notification .notifyClose {
  padding: 10px 30px;
  display: block;
}
